@mixin respond($breakpoint) {
    @if $breakpoint == phone {
        @media only screen and (max-width: 37.5em) { @content };    //600px
    }
    @if $breakpoint == tab-port {
        @media only screen and (max-width: 56.25em) { @content };     //900px
    }
    @if $breakpoint == tab-land {
        @media only screen and (max-width: 75em) { @content };    //1200px
    }
    @if $breakpoint == big-desktop {
        @media only screen and (min-width: 112.5em) { @content };    //1800
    }
}

.u-margin-bottom-small { margin-bottom: 1.3rem !important;
    @include respond(tab-port) {
        margin-bottom: 0 !important;
    } }

.u-center-text { text-align: center !important; }

.u-margin-bottom-medium { margin-bottom: 2rem !important; }
.u-margin-bottom-big { margin-bottom: 8rem !important; }

.u-margin-top-big { margin-top: 8rem !important; }
.u-margin-top-huge { margin-top: 10rem !important; }

header {
    background-image: -webkit-linear-gradient(#12013688, #12013688), url(sea.jpg);
    background-image: linear-gradient(#12013688, #12013688), url(sea.jpg);
    background-size: cover;
    background-position: center;
    height: 100vh;
    width: auto;
    background-attachment: fixed;

    @include respond(tab-land) {
        background-image: -webkit-linear-gradient(#120136, #120136);
        background-image: linear-gradient(#120136, #120136);
    }
}

.heading-primary-main {
    animation-name: moveInLeft;
    animation-duration: 1s;
    animation-timing-function: ease-out;
    position: relative;
    left: 5px;
    top: 15px;
    
    @include respond(phone) {
        font-size: 20px;
        left: 0px;
    }
}

.btn-full:link,
.btn-full:visited,
input[type=submit] {
  background-color: #40bad5;
  border: 1px solid #40bad5;
  color: #fff;
  margin-right: 15px;
  animation-name: moveInTop;
  animation-duration: 2s;
}

.btn-ghost:link,
.btn-ghost:visited {
  border: 1px solid #40bad5;
  color: #40bad5;
  animation-name: moveInTop;
  animation-duration: 2s;
}

.main-nav {
    float: right;
    list-style: none;
    margin-top: 55px;
    animation-name: moveInRightNav;
    animation-duration: 3s;
    animation-timing-function: ease-out;
}

.logo {
    height: 70px;
    width: auto;
    float: left;
    margin-top: 30px;
    animation-name: moveInBottom;
    animation-duration: 2s;
    animation-timing-function: ease-out;
}

.heading-primary-sub {
    animation-name: moveInRight;
    animation-duration: 1s;
    animation-timing-function: ease-out;
    position: relative;

    @include respond(phone) {
        font-size: 20px;
    }
}

.section-testimonials {
    background-image: -webkit-linear-gradient(#120136c2, #120136c2), url(promajna.jpg);
    background-image: linear-gradient(#120136c2, #120136c2), url(promajna.jpg);
    background-size: cover;
    color: #fff;
    background-attachment: fixed;
}

@keyframes moveInLeft {
    0% {
        opacity: 0;
        transform: translateX(-100px);
    }

    80% {
        transform: translateX(10px);
    }

    100% {
        opacity: 1;
        transform: translate(0);
    }
}

@keyframes moveInRight {
    0% {
        opacity: 0;
        transform: translateX(100px);
    }

    80% {
        transform: translateX(-10px);
    }

    100% {
        opacity: 1;
        transform: translate(0);
    }
}

@keyframes moveInRightNav {
    0% {
        opacity: 0;
        transform: translateX(400px);
    }

    50% {
        opacity: 0;
        transform: translateX(400px);
    }

    80% {
        transform: translateX(-10px);
    }

    100% {
        opacity: 1;
        transform: translate(0);
    }
}

@keyframes moveInTop {
    0% {
        opacity: 0;
        transform: translatey(100px);
    }
    
    30% {
        opacity: 0;
        transform: translatey(100px);
    }

    80% {
        transform: translatey(-10px);
    }

    100% {
        opacity: 1;
        transform: translate(0);
    }
}

@keyframes moveInBottom {
    0% {
        opacity: 0;
        transform: translatey(-100px);
    }
    
    30% {
        opacity: 0;
        transform: translatey(-100px);
    }

    80% {
        transform: translatey(-10px);
    }

    100% {
        opacity: 1;
        transform: translate(0);
    }
}

.broj {
    float: left;
}

p a {
    &:link,
    &:visited {
        color: black;
        text-decoration: none;
        display: block;
    }

    &:hover,
    &:active {
        color: rgb(97, 97, 97);
        text-decoration: none;
    }
}

.feature-box {
    background-color: rgba(white, .4);
    font-size: 0.9rem;
    padding: 2.5rem;
    text-align: center;
    border-radius: 3px;
    box-shadow: 0 1.5rem 4rem rgba(black, .15);
    transition: transform .3s;
    position: relative;

    @include respond(tab-port) {
        padding: 2rem;
        font-size: 0.6rem;
    }

    @include respond(phone) {
        height: 10.5rem;
        font-size: 0.8rem;
    }
  
    &__icon {
        font-size: 6rem;
        margin-bottom: .5rem;
        display: inline-block;
        background-image: linear-gradient(to right, #035aa6, #40bad5);
        -webkit-background-clip: text;
        color: transparent;

        @include respond(tab-port) {
            margin-bottom: 0;
            font-size: 3.5rem;
        }

        @include respond(phone) {
            font-size: 3rem;
        }
    }
  
    &:hover {
        transform: translateY(-1.5rem) scale(1.03);
    }
}

.section-steps {
    background-color: #f4f4f4;

    @include respond(phone) {
        height: 670px;
    }
}

.works-step div {
    position: relative;
    color: #40bad5;
    font-size: 150%;
    height: 54px;
    width: 54px;
    border: 2px solid #40bad5;
    border-radius: 50%;
    display: inline-block;
    text-align: center;
    margin-right: 25px;
    float: left;
    padding: 5px;

    @include respond(phone) {
        top: -10px;
    }
}

.works-step {
    margin-bottom: 50px;
    position: relative;

    @include respond(phone) {
        top: 70px;
    }
}

.works-step:last-of-type {
    margin-bottom: 80px;

    @include respond(phone) {
        top: 70px;
    }
}

.parasailing {
    position: relative;
    top: 60px;
    width: 70%;
    box-shadow: 0 1.5rem 4rem;
    transition: transform .6s;

    &:hover {
        transform: translateY(-1.5rem) scale(1.03);
    }

    @include respond(phone) {
        top: 35px;
    }
  }

.person {
    color: #120136;
    margin-right: 5px;
}
.email {
    position: relative;
    bottom: 5px;

    @include respond(tab-land) {
        font-size: 15px;
    }
}

.footer__link {
    &:link,
    &:visited {
        color: #40bad5 !important;
        background-color: #120136 !important;
        text-decoration: none;
        text-transform: uppercase;
        display: inline-block !important;
        transition: all .2s;
    }

    &:hover,
    &:active {
        color: #40bad5 !important;
        box-shadow: 0 1rem 2rem rgba(black, .6);
        transform: rotate(5deg) scale(1.3);
        text-decoration: none;
        background-color: #120136 !important;
    }
}

.footer__item {
    display: inline-block;

    &:not(:last-child) {
        margin-right: 1.5rem;
    }
}

.apartmani {
    background-color: #FFFFFF;
    padding: 5px;
    box-shadow: 0 1.5rem 4rem rgba(black, .1);
    border-radius: 2%;
    transition: transform .3s;

    &:hover {
        transform: translateY(-1.5rem) scale(1.03);
        cursor: pointer;
    }
}

.apart-orka1 {
    height: 100px;

    @include respond(phone) {
        height: 50px;
    }
}

.home-orka1 {
    height: 100px;

    @include respond(phone) {
        height: 50px;
    }
}

.holiday-home {
    background-color: white;
    padding: 5px;
    box-shadow: 0 1.5rem 4rem rgba(black, .25);
    border-radius: 2%;
    transition: transform .3s;

    &:hover {
        transform: translateY(-1.5rem) scale(1.03);
        cursor: pointer;
    }
}

.row a {
    text-align: center;
    &:link,
    &:visited {
        color: black;
        text-decoration: none;
        display: initial;
    }

    &:hover,
    &:active {
        color: rgb(97, 97, 97);
        text-decoration: none;
    }
}

.row-orka3 {
    max-width: 900px;
    margin: auto;
    text-align: center;
    font-size: 25px;

    @include respond(phone) {
        font-size: 17px;
    }
}

.row-orka3 a {

    &:link,
    &:visited {
        color: black;
        text-decoration: none;
        display: initial;
    }

    &:hover,
    &:active {
        color: rgb(97, 97, 97);
        text-decoration: none;
    }
}

/* Big tablet to 1200px (widths smaller taht the 1140px row) */
@media only screen and (max-width: 1200px) {
    .hero-text-box {
        width: 100%;
        padding: 0 2%;
    }
    
    .row { padding: 0 2%; }
}


/* Small tablet to big tablet: from 768px to 1023px */
@media only screen and (max-width: 1023px) {
    body { font-size: 18px; }
    section { padding: 60px 0; }
    
    .long-copy {
        width: 80%;
        margin-left: 10%;
    }
    
    .steps-box { margin-top: 10px; }
    .steps-box:last-child { margin-top: 10px; }
    .works-steps { margin-bottom: 40px; }
    .works-step:last-of-type { margin-bottom: 60px; }
    
    .app-screen { width: 50%; }
    
    .icon-small { 
        width: 17px;
        margin-right: 5px;
    }
    
    .city-feature { font-size: 90%; }
    
    .plan-box {
        width: 100%;
        margin-left: 0%;
    }
    
    .plan-price { font-size: 250%; }
    .contact-form { width: 80%; }
}



/* Small phones to small tablets: from 481px to 767px */
@media only screen and (max-width: 767px) {
    body { font-size: 16px; }
    section { padding: 30px 0; }
    
    .row,
    .hero-text-box { padding: 0 4%; }
    .col { 
        width: 100%;
        margin: 0 0 4% 0;
    }
    
    
    .main-nav { display: none; }
    .mobile-nav-icon {display: inline-block;}
    
    .main-nav {
        float: left;
        margin-top: 30px;
        margin-left: 25px;
    }
    
    .main-nav li {
        display: block;
    }
    
    .main-nav li a:link,
    .main-nav li a:visited {
        display: block;
        border: 0;
        padding: 10px 0;
        font-size: 100%;
    }
    
    .sticky .main-nav { margin-top: 10px; }

    .sticky .main-nav li a:link,
    .sticky .main-nav li a:visited { padding: 10px 0; }
    .sticky .mobile-nav-icon { margin-top: 10px; }
    .sticky .mobile-nav-icon i { color: #555; }
    
    
    
    h1 { font-size: 180%; }
    h2 { font-size: 150%; }
    
    .long-copy {
        width: 100%;
        margin-left: 0%;
    }
    
    .app-screen { width: 40%; }
    .steps-box:first-child { text-align: center; }
    
    .works-step div {
        height: 40px;
        width: 40px;
        margin-right: 15px;
        padding: 4px;
        font-size: 120%;
    }
    
    .works-step { margin-bottom: 20px; }
    .works-step:last-of-type { margin-bottom: 20px; }
    
}



/* Small phones: from 0 to 480px */
@media only screen and (max-width: 480px) {
    section { padding: 25px 0; }
    .contact-form {width: 100%; }
}
