/*
Orange color: #40bad5

*/

/* ----------------------------------------------- */
/* BASIC SETUP */
/* ----------------------------------------------- */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  background-color: #fff;
  color: #555;
  font-family: 'Lato', 'Arial', sans-serif;
  font-weight: 300;
  font-size: 20px;
  text-rendering: optimizeLegibility;
  overflow-x: hidden;
}

.clearfix {zoom: 1;}
.clearfix:after {
  content: '.';
  clear: both;
  display: block;
  height: 0;
  visibility: hidden;
}

/* ----------------------------------------------- */
/* REUSABLE COMPONENTS */
/* ----------------------------------------------- */


.row {
  max-width: 1140px;
  margin: 0 auto;
}

section {
  padding: 80px 0;
}

.box {
  padding: 1%;
}

/* ----- HEADINGS ----- */
h1,
h2,
h3 {
  font-weight: 300;
  text-transform: uppercase;
}

h1 {
  margin-top: 0;
  margin-bottom: 20px;
  color: #fff;
  font-size: 240%;
  word-spacing: 4px;
  letter-spacing: 1px;
}

h2 {
  font-size: 180%;
  word-spacing: 2px;
  text-align: center;
  margin-bottom: 30px;
  letter-spacing: 1px;
}

h3 {
  font-size: 110%;
  margin-bottom: 15px;
}

h2:after {
  display: block;
  height: 2px;
  background-color: #40bad5;
  content: " ";
  width: 100px;
  margin: 0 auto;
  margin-top: 30px;
}

.logo {
  position: relative;
  top: 10px;
  color: #40bad5;
  font-size: 40px;
}

/* ----- PARAGRAPHS ----- */
.long-copy {
  text-align: center;
  line-height: 145%;
  width: 70%;
  margin-left: 15%;
}

.box p {
  font-size: 90%;
  line-height: 145%;
  height: 55px;
}


/* ----- ICONS ----- */
.icon-big {
  font-size: 350%;
  display: block;
  color: #40bad5;
  margin-bottom: 10px;
}

.icon-small {
  display: inline-block;
  width: 30px;
  text-align: center;
  color: #40bad5;
  font-size: 120%;
  margin-right: 10px;
  
  /*secrets to align text and icons*/
  line-height: 120%;
  vertical-align: middle;
  margin-top: -5px;
}

/* ----- LINKS ----- */

.navlink:link,
.navlink:visited {
  color: #40bad5;
  text-decoration: none;
  padding-bottom: 1px;
  border-bottom: 1px solid #40bad5;
  -webkit-transition: border-bottom 0.2s, color 0.2s;
  transition: border-bottom 0.2s, color 0.2s;
}

.navlink:hover,
.navlink:active {
  color: #555;
  border-bottom: 1px solid transparent;
}

/* ----- BUTTONS ----- */
.btn:link,
.btn:visited,
input[type=submit] {
  display: inline-block;
  padding: 10px 30px;
  font-weight: 300;
  text-decoration: none;
  border-radius: 200px;
  -webkit-transition: background-color 0.2s, border 0.2s, color 0.2s;
  transition: background-color 0.2s, border 0.2s, color 0.2s;
}

.btn-full:link,
.btn-full:visited,
input[type=submit] {
  background-color: #40bad5;
  border: 1px solid #40bad5;
  color: #fff;
  margin-right: 15px;
}

.btn-ghost:link,
.btn-ghost:visited {
  border: 1px solid #40bad5;
  color: #40bad5;
}

.btn:hover,
.btn:active,
input[type=submit]:hover,
input[type=submit]:active {
  background-color: #035aa6;
}

.btn-full:hover,
.btn-full:active {
  border: 1px solid #035aa6;
}

.btn-ghost:hover,
.btn-ghost:active {
  border: 1px solid #035aa6;
  color: #fff;
}


/* ----------------------------------------------- */
/* HEADER */
/* ----------------------------------------------- */


.hero-text-box {
  position: absolute;
  width: 1140px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.logo-black { 
  display: none;
  height: 50px;
  width: auto;
  float: left;
  margin: 5px 0;
}

/* Main navi */

.main-nav li {
  display: inline-block;
  margin-left: 40px;
}

.main-nav li a:link,
.main-nav li a:visited {
  padding: 8px 0;
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 90%;
  border-bottom: 2px solid transparent;
  -webkit-transition: border-bottom 0.2s;
  transition: border-bottom 0.2s;
}

.main-nav li a:hover,
.main-nav li a:active {
  border-bottom: 2px solid #40bad5;
}


/* Mobile navi */
.mobile-nav-icon {
  float: right;
  margin-top: 30px;
  cursor: pointer;
  display: none;
}

.mobile-nav-icon i {
  font-size: 200%;
  color: #fff;
}


/* Sticky navi */
.sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.98);
  box-shadow: 0 2px 2px #efefef;
  z-index: 9999;
}

.sticky .main-nav { margin-top: 18px; }

.sticky .main-nav li a:link,
.sticky .main-nav li a:visited {
  padding: 16px 0;
  color: #555;
}

.sticky .logo { display: none; }
.sticky .logo-black { display: block; }

/* ----------------------------------------------- */
/* FEATURES */
/* ----------------------------------------------- */

.section-features .long-copy {
  margin-bottom: 30px;
}


/* ----------------------------------------------- */
/* MEALS */
/* ----------------------------------------------- */

.photos {
  padding: 0;
}

.meals-showcase {
  list-style: none;
  width: 100%;
}

.meals-showcase li {
  display: block;
  float: left;
  width: 25%;
}

.apartment-photo {
  width: 100%;
  margin: 0;
  overflow: hidden;
  background-color: #120136;
}

.apartment-photo img {
  opacity: 0.6;
  width: 100%;
  height: auto;
  -webkit-transform: scale(1.15);
  -ms-transform: scale(1.15);
  transform: scale(1.15);
  -webkit-transition: -webkit-transform 0.5s, opacity 0.5s;
  transition: transform 0.5s, opacity 0.5s;
}

.apartment-photo img:hover {
  opacity: 1;
  -webkit-transform: scale(1.03);
  -ms-transform: scale(1.03);
  transform: scale(1.03);
}



/* ----------------------------------------------- */
/* HOW IT WORKS SECTION */
/* ----------------------------------------------- */


.steps-box {
  margin-top: 30px;
}


.steps-box:first-child {
  text-align: right;
  padding-right: 3%;
}

.steps-box:last-child {
  text-align: left;
  padding-left: 3%;
  margin-top: 70px;
}




.btn-app:link,
.btn-app:visited {
  border: 0;
}

.btn-app img {
  height: 50px;
  width: auto;
  margin-right: 10px;
}


/* ----------------------------------------------- */
/* CITIES */
/* ----------------------------------------------- */

.box img {
  border-radius: 2%;
  width: 100%;
  height: 100%;
  margin-bottom: 15px;
}


.apartment-feature {
  margin-bottom: 5px;
}


/* ----------------------------------------------- */
/* TESTIMONIALS */
/* ----------------------------------------------- */

blockquote {
  padding: 2%;
  font-style: italic;
  line-height: 145%;
  position: relative;
  margin-top: 40px;
}

blockquote:before {
  content: "\201C";
  font-size: 500%;
  display: block;
  position: absolute;
  top: -5px;
  left: -5px;
}

.apartments {
  font-size: 90%;
  margin-top: 25px;
  display: block;
}

.apartments img {
  height: 45px;
  border-radius: 50%;
  margin-right: 10px;
  vertical-align: middle;
}


/* ----------------------------------------------- */
/* SIGN UP */
/* ----------------------------------------------- */

.section-plans {
  background-color: #f4f4f4;
}

.plan-box {
  background-color: #fff;
  border-radius: 5px;
  width: 90%;
  margin-left: 5%;
  box-shadow: 0 2px 2px #efefef;
}

.plan-box div {
  padding: 15px;
  border-bottom: 1px solid #e8e8e8;
}

.plan-box div:first-child {
  background-color: #fcfcfc;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.plan-box div:last-child {
  text-align: center;
  border: 0;
}


.plan-price {
  font-size: 300%;
  margin-bottom: 10px;
  font-weight: 100;
  color: #40bad5;
}

.plan-price span {
  font-size: 30%;
  font-weight: 300;
}

.plan-price-meal {
  font-size: 80%;
}

.plan-box ul {
  list-style: none;
}

.plan-box ul li {
  padding: 5px 0;
}


/* ----------------------------------------------- */
/* FORM */
/* ----------------------------------------------- */

.contact-form {
  width: 60%;
  margin: 0 auto;
}

input[type=text],
input[type=email],
select,
textarea {
  width: 100%;
  padding: 7px;
  border-radius: 3px;
  border: 1px solid #ccc;
}

textarea {
  height: 100px;
}

input[type=checkbox] {
  margin: 10px 5px 10px 0;
}

*:focus {outline: none;}


/* ----------------------------------------------- */
/* FOOTER */
/* ----------------------------------------------- */

footer {
  background-color: #120136;
  padding: 50px;
  font-size: 80%;
}

.footer-nav {
  list-style: none;
  margin: auto;
  text-align: center;
}

.footer-nav li,
.social-links li {
  display: inline-block;
  margin-right: 20px;
  text-decoration: none;
}

.footer-nav li:last-child,
.social-links li:last-child {
  margin-right: 0;
  text-decoration: none;
}



.social-links li a:link,
.social-links li a:visited {
  font-size: 160%;
}

.ion-social-facebook,
.ion-social-twitter,
.ion-social-googleplus,
.ion-social-instagram {
  -webkit-transition: color 0.2s;
  transition: color 0.2s;
}

.ion-social-facebook:hover {
  color: #3b5998;
}

.ion-social-twitter:hover {
  color: #00aced;
}

.ion-social-googleplus:hover {
  color: #dd4b39;
}

.ion-social-instagram:hover {
  color: #517fa4;
}


footer p {
  color: #888;
  text-align: center;
  margin-top: 20px;
  text-decoration: none;
}


/* ----------------------------------------------- */
/* Animations */
/* ----------------------------------------------- */
